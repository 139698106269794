import React, { useContext } from 'react';
import { TicketContext } from '../Context';

export default function ConfirmationPage() {
	const { context } = useContext(TicketContext);

	return (
		<div>
			<div style={{ color: "#505050" }} className="font-weight-bold text-center">
				<p>Thank you!!</p>
				<p>Your request has been captured as TICK:{context.responseData.IDs[0]} and you will receive a confirmation email shortly.​</p>
			</div>
			<div style={{ color: "#A0A0A0" }} className="text-center">
				<p>For more information about the Service Desk processes, please see the <a href="https://bw1.sharepoint.com/sites/DGKB/_layouts/15/search.aspx/siteall?q=IT%20service%20desk" >Service Desk support pages​</a></p>
			</div>
		</div>
		);
} 
