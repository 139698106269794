import React, { useContext } from 'react';
import { TicketContext } from '../Context';

import { categories } from "../Data/CategoryData_prod";

export default function Category() {
	const { context, updateForm } = useContext(TicketContext);

	const handleCategorySelection = event => {
		var category;

		if (event.target.value === "") {
			category = { title: "", value: "" };
		}
		else {
			category = categories.find(cat => cat.value === event.target.value);
		}

		updateForm({
			...context.ticketData,
			category: category,
			subcategory: { title: "", value: "" }
		});
	};

	return (
		<div className="form-group">
			<label htmlFor="category">Category</label>
			<div>
				<select id="category" className="form-control d-inline" onChange={handleCategorySelection} value={context.ticketData.category.value}>
					{categories.map(category =>
						<option value={category.value} key={category.value}>{category.title}</option>
					)};
				</select>
			</div>
		</div>	
	);
}