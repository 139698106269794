import React, { useContext } from 'react';
import { TicketContext } from '../Context';

export default function SeveritySelect() {
	const { context, updateForm } = useContext(TicketContext);

	const handleSelection = event => {
		updateForm({
			...context.ticketData,
			requestedPriority: event.target.value
		});
	};

	return (
		<div className="form-group">
			<label htmlFor="requestedPriority">Priority</label>
			<div>
				<select id="requestedPriority" className="form-control" onChange={handleSelection} value={context.ticketData.requestedPriority}>
					<option value="Critical">Critical</option>
					<option value="High">High</option>
					<option value="Normal">Normal</option>
					<option value="Low">Low</option>
				</select>
			</div>
		</div>
	);
}