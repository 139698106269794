export const categories = [
    { 
        title: "Please select one...",
        value: "86"
    },
    { 
        title: "Account",
        value: "115",
        subcategories: [
            { title: "Please select one...", value: "115" },
            { title: "AD Account Change", value: "215" },
            { title: "EMS Account Changes", value: "320" },
            { title: "Offboarding", value: "309" },
            { title: "Okta", value: "551" },
            { title: "Other", value: "271" },
            { title: "Reset or Unlock", value: "126" }
        ]
    },
    { 
        title: "Application - Desktop/PC",
        value: "117",
        subcategories: [
            { title: "Please select one...", value: "117" },
            { title: "Antivirus/Malware", value: "263" },
            { title: "AutoCAD", value: "347" },
            { title: "Bluebeam", value: "308" },
            { title: "Microsoft OneDrive", value: "429" },
            { title: "Microsoft Outlook", value: "674", subcategories: [
                { title: "Other", value: "278" },
                { title: "Signature", value: "285" }
            ] },
            { title: "Microsoft Teams", value: "462" },
            { title: "Microsoft Windows", value: "283" },
            { title: "Other", value: "237" },
            { title: "Rockwell", value: "236", subcategories: [ 
                { title: "Other", value: "342" } 
            ] },
            { title: "SPAM/Phishing", value: "290" },
            { title: "VPN", value: "195" }
        ]
    },
    { 
        title: "Application - Enterprise",
        value: "118",
        subcategories: [
            { title: "Please select one...", value: "118" },
            { title: "Business Intelligence", value: "119" },
            { title: "EMS", value: "121" },
            { title: "OMS", value: "560" },
            { title: "Other", value: "123" },
            { title: "SharePoint", value: "124" }
        ]
    },
    { 
        title: "Hardware",
        value: "116",
        subcategories: [
            { title: "Please select one...", value: "116" },
            { title: "Printer/Copier", value: "172" },
            { title: "Computer", value: "677", subcategories: [
                { title: "Other", value: "571" },
                { title: "New Hire", value: "168" },
                { title: "Replenish", value: "171" },
                { title: "Replacement", value: "170" }
            ] },
            { title: "Desk Phone", value: "566" },
            { title: "Failure", value: "567" },
            { title: "Other", value: "177" },
            { title: "Purchase", value: "568" }
        ]
    },
    { 
        title: "Network",
        value: "114",
        subcategories: [
            { title: "Please select one...", value: "114" },
            { title: "Other", value: "148" }
        ]
    },
    { 
        title: "Request",
        value: "113",
        subcategories: [
            { title: "Please select one...", value: "113" },
            { title: "Archive Data", value: "326" },
            { title: "File Restore", value: "217" },
            { title: "Hardware", value: "220" },
            { title: "Network Drive", value: "240" },
            { title: "Other", value: "270" },
            { title: "Personal Data Access", value: "572" },
            { title: "Software Install", value: "128" }
        ]
    },
    { 
        title: "Server",
        value: "157"
    }
]