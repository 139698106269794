export default (state, action) => {
    switch (action.type) {

        case "AUTHORIZE":
            return {
                ...state,
                isAuthorized: true,
                userData: action.payload
            };

        case "LOGOUT":
            return {
                ...state,
                isAuthorized: false,
                userData: null
            };

        case "CLEAR_FORM":
            return {
                ...state,
                ticketData: action.payload
            };

        case "UPDATE_FORM":
            return {
                ...state,
                ticketData: action.payload
            };

        case "ERROR_RECEIVED":
            return {
                ...state,
                error: action.payload
            };

        case "RESPONSE_RECEIVED":
            return {
                ...state,
                responseData: action.payload
            };

        case "UPDATE_BANNER":
            return {
                ...state,
                bannerData: action.payload
            };

        default:
            return state;
    };
};