import React, { useContext, useEffect, useState } from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import { TicketContext } from '../Context';
import SeveritySelect from '../controls/SeveritySelect';
import { submitTicket } from '../services/KaceService';
import EmailList from '../controls/EmailList';
import Category from '../controls/Category';
import FileUpload from '../controls/FileUpload';
import { useHistory } from "react-router-dom";
import { tokenRequest } from "../services/authConfig_Prod";
import { InteractionRequiredAuthError } from "@azure/msal-common"
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";

import { Editor } from '@tinymce/tinymce-react';

export default function KaceTicketForm() {
	const { context, clearForm, submitForm, updateForm, updateBanner } = useContext(TicketContext);
	const [ticketData, setTicketData] = useState(context.ticketData);
	const [userText, setUserText] = useState(context.ticketData.summary);
	let history = useHistory();
	const appInsights = useAppInsightsContext();
	const trackComponent = useTrackMetric(appInsights, "KaceTicketForm");

	const { instance, accounts } = useMsal();
	const account = useAccount(accounts[0] || {});

	const getToken = (callback) => {
		if (account) {
			instance.acquireTokenSilent({
				...tokenRequest,
				account: account
			}).then((response) => {
				callback(response.accessToken);
			}).catch(async (error) => {
				if (error instanceof InteractionRequiredAuthError) {
					// fallback to interaction when silent call fails
					return instance.acquireTokenPopup({
						...tokenRequest,
						account: account
					});
				}
			}).catch(error => {
				updateBanner({
					message: "An authentication error occured",
					type: "danger"
				});
			});
		}
	};

	// Callback for value change, updating the value in context
	const handleChange = (event) => {
		updateForm({
			...context.ticketData,
			[event.target.id]: event.target.value
		});
	};

	const handleSubmit = async (event) => {
		trackComponent();

		const submissionData = {
			...ticketData,
			summary: userText,
			userName: account.name,
			userEmail: account.username
		}

		const onSuccess = (success) => {
			submitForm(success.data);
			clearForm();
			history.push('/confirmation');
		};

		const onFailure = (error) => {
			updateBanner({
				message: "Ticket could not be submitted!",
				type: "danger"
			});
		};

		getToken((resp) => { submitTicket(submissionData, resp, onSuccess, onFailure);})
	};

	const handleTextEdit = (newText) => {
		setUserText(newText);
	};

	const formValid = () => {
		if (!userText
			|| !ticketData.category.value
			|| !ticketData.title) {
			return false;
		}

		return true;
	}

	useEffect(() => { setTicketData(context.ticketData);}, [context.ticketData]);

    return (
		<div>
			<div className="d-flex flex-row flex-wrap">
				<div className="pr-2 align-self-center"><h3>How can we help you?</h3></div>
				<div className="pr-2 align-self-center"><span style={{ fontStyle: 'italic' }}> please specify urgency if time sensitive</span></div>
			</div>
			{/*<div className="form-group">
				<h3 className="d-inline">How can we help you?</h3><span className="d-inline"> please specify urgency if time sensitive</span>
			</div>*/}
			<div className="form-group">
				<label htmlFor="title">Brief Description<span style={{ color: 'red' }}>*</span></label>
				<div>
					<input id="title" type="text" onChange={handleChange} className="form-control"/>
				</div>
			</div>
			<label>Details<span style={{ color: 'red' }}>*</span></label>
			<Editor
				apiKey="pkabuwxkrhuas90qfadw3ks0ivfptjggrtjm8krfz8my05xd"
				init={{
					height: 200,
					menubar: false,
					plugins: [
						'advlist autolink lists link image charmap print preview anchor',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime media table paste code help wordcount'
					],
					toolbar:
						'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
					browser_spellcheck: true,
					contextmenu: false
				}}
				value={ticketData.summary}
				onEditorChange={(text) => handleTextEdit(text)}
			/>
			<FileUpload />
			<EmailList />
			<div className="d-flex flex-row flex-wrap">
				<div className="pr-2 align-self-center"><SeveritySelect /></div>
				<div className="pr-2 align-self-center"><Category /></div>
				<div className="pr-2 align-self-center">
					<div className="form-group">
						<label htmlFor="callbackNumeber">Callback Number</label>
						<div>
							<input
								id="callbackNumber"
								className="form-control"
								onChange={handleChange} />
						</div>
					</div>
				</div>
			</div>
			<button
				type="button"
				className={`btn ${formValid() ? 'btn-primary' : 'btn-dark' } d-inline`}
				disabled={!formValid()}
				onClick={handleSubmit}>
				Submit
			</button>
			{!formValid() && <span className="d-inline" style={{ color: 'red' }}>&nbsp;* Please complete all required fields</span>}
        </div>
    );
}