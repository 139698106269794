import React, { useContext } from 'react';
import { TicketContext } from '../Context';

export default function FileUpload() {
	const { context, updateForm } = useContext(TicketContext);

	const handleSelect = event => {
		updateForm({
			...context.ticketData,
			attatchment: event.target.files[0]
		});
	};

	return (
		<div className="form-group">
			<div>
				<input className="form-control-file" type="file" onChange={handleSelect} />
			</div>
			{/*<div className="custom-file">
				<input type="file" className="custom-file-input" id="customFile" onChange={handleSelect} />
					<label class="custom-file-label" for="customFile">Load File</label>
			</div>*/}
		</div>
	);
}