import axios from 'axios';

export const submitTicket = async (ticketData, token, success, failure) => {

    try {
        const formData = new FormData();

        formData.append('attatchment', ticketData.attatchment);

        let trimmedTicket = { ...ticketData };

        delete trimmedTicket.attatchment;
        let ticketJson = JSON.stringify(trimmedTicket);

        formData.append('ticketData', ticketJson);

        let config = {
            headers: {
                Authorization: 'Bearer ' + token,
                'content-type': 'multipart/form-data'
            }
        };

        const resp = await axios.post('api/kace/submit', formData, config);

        success(resp);
    }
    catch (error) {
        failure(error);
    }
};

export const getUserEmail = async (searchString, token, success, failure) => {
    try {
        let config = {
            headers: {
                Authorization: 'Bearer ' + token
            }
        };

        const resp = await axios.get('api/Kace/searchuser?query=' + searchString, config);

        success(resp.data);
    }
    catch (error) {
        failure(error);
    }
};