import React from 'react';
import { Container, Navbar, NavbarBrand/*, NavItem, NavLink*/ } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import Logo from "../Images/design-group.png";

export function NavMenu() {

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                <Container>
                    <NavbarBrand tag={Link} to="/">
                        <img border="0" src={Logo} width="50" className="fr-fic fr-dii" alt="design-group.png" />
                        &nbsp;
                        IT Service Request
                    </NavbarBrand>
                    <ul className="navbar-nav flex-grow">
                        {/*<NavItem>
                            <NavLink tag={Link} className="text-dark" to="/">New Ticket</NavLink>
                        </NavItem>*/}
                        {/*<NavItem>
                            <button type="button" className="btn btn-primary" onClick={logout}>Logout</button>
                        </NavItem>*/}
                    </ul>
                </Container>
            </Navbar>
        </header>
    );
}
