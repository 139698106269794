import React, { createContext, useReducer } from "react";
import TicketReducer from "./Reducer";

import { categories } from "./Data/CategoryData_prod";

const initialBannerData = {
	message: "",
	type: ""
};

const initialState = {
	ticketData: {
		summary: "",
		category: categories[0],
		subcategory: { title: "", value: "" },
        submitter: null,
        requestedPriority: "Normal",
        callbackNumber: "",
        title: "",
        ccList: [],
        attatchment: null
    },

    responseData: null,

    error: {
        errorCode: "",
		errorDescription: ""
	},

	bannerData: initialBannerData,

	categories
};

export const TicketContext = createContext(initialState);
export const TicketProvider = ({ children }) => {
    const [state, dispatch] = useReducer(TicketReducer, initialState);

    // State Modification Functions

    function clearForm() {
        dispatch({
            type: "CLEAR_FORM",
            payload: initialState.ticketData
        });
    };

    function updateForm(formData) {
        dispatch({
            type: "UPDATE_FORM",
            payload: formData
        });
    };

    function errorReceived(error) {
        dispatch({
            type: "ERROR_RECEIVED",
            payload: error
        });
    };

    function submitForm(responseData) {
        dispatch({
            type: "RESPONSE_RECEIVED",
            payload: responseData
        });
    };

	function updateBanner(banner) {
		dispatch({
			type: "UPDATE_BANNER",
			payload: banner ? banner : initialBannerData
		});
	};


    return (
        <TicketContext.Provider value={{
            context: state,
            clearForm,
            updateForm,
            errorReceived,
            submitForm,
			updateBanner,
			bannerData: state.bannerData
        }}>
            {children}
        </TicketContext.Provider>
    );
};