import React from "react";

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./services/authConfig_Prod";
import App from "./App";

const msalInstance = new PublicClientApplication(msalConfig);

export default function MSALAppProvider() {
    return (
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    );
};