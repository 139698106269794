import React, { useContext } from 'react';
import { TicketContext } from "../Context";


export default function Banner() {
    const { bannerData, updateBanner } = useContext(TicketContext);

    return (
        <div
            className={"alert alert-" + bannerData.type + " alert-dismissible " + (bannerData.message ? "show" : "fade")}
            role="alert"
            style={{ display: bannerData.message ? 'block' : 'none' }}
        >
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={updateBanner}>
                <span aria-hidden="true" >&times;</span>
            </button>
            <div dangerouslySetInnerHTML={{ __html: bannerData.message }}></div>
        </div>
    );
}