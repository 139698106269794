// For a full list of msal.js configuration parameters, 
// visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
// Dev
export const msalConfig = {
    auth: {
        clientId: "e5a2361c-c248-44a2-a178-36bc02ee8f9d",
        authority: "https://login.microsoftonline.com/619282f7-d1dc-49f4-857f-86a827636052",
        redirectUri: "https://it.bwdesigngroup.com:443/signin-oidc"
    },
    cache: {
        cacheLocation: "sessionStorage"
    },
}
       
// Coordinates and required scopes for your web api
export const apiConfig = {
    resourceUri: "https://it.bwdesigngroup.com/api/profile",
    //resourceScope: "Enter the API scopes as declared in the app registration 'Expose an Api' blade in the form of 'api://{client_id}/.default'"
    resourceScope: "api://e5a2361c-c248-44a2-a178-36bc02ee8f9d/Ticket.Create"
}

/** 
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters, 
 * visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const loginRequest = {
    scopes: ["profile", "User.Read", "api://e5a2361c-c248-44a2-a178-36bc02ee8f9d/Ticket.Create"]
}

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequest = {
    scopes: [apiConfig.resourceScope],
    forceRefresh: true // Set this to "true" to skip a cached token and go to the server to get a new token
}

// Add here scopes for silent token request
export const silentRequest = {
    scopes: ["openid", "profile", apiConfig.resourceScope]
}
