import React, { useState, useEffect } from 'react';
import '../Styles/DynamicSearchBox.css';

export default function DynamicSearchBox(props) {
	const [options, setOptions] = useState([]);
	const [query, setQuery] = useState("");
	const [clickFlag, setClickFlag] = useState(false);
	const [resetFlag, setResetFlag] = useState(false);

	const getOptionsApi = props.getOptions;

	const handleTyping = event => {
		setQuery(event.target.value);
	};

	const clickBack = item => {
		setClickFlag(true);
		props.callback(item);
		setQuery("");
		setOptions([]);
	};

	const handleOnBlur = () => {
		setOptions([]);
	};

	useEffect(() => {
		if (clickFlag || resetFlag) {
			setClickFlag(false);
			setResetFlag(false);
			setOptions([]);
		}
		else if (query  && query.length > 2) {
			getOptionsApi(query, setOptions);
		}
		else {
			setOptions([]);
        }
	}, [query]);

	return (
		<div className="form-group form-group-overlay">
			<input name={props.controlID}
				className="form-control"
				autoComplete="off"
				id={props.selectID}
				onChange={handleTyping}
				value={query}
				placeholder={"Start typing a " + props.displayName + "..."}
				onBlur={handleOnBlur} />
			<ul className="list-group overlay-group" style={{ display: options.length > 0 ? 'block' : 'none', zIndex: 0, position: 'relative' }}>
				{options.map(item => (
					<li className="list-group-item overlay-item" onMouseDown={() => clickBack(item)} key={item.id}>{item.name}</li>
					))}
			</ul>
		</div>
	)
}