import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import DynamicSearchBox from './DynamicSearchBox';
import { TicketContext } from '../Context';
import { getUserEmail } from '../services/KaceService';
import { tokenRequest } from "../services/authConfig_Prod";
import { useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-common"

export default function EmailList() {
	const { context, updateForm, errorReceived, updateBanner } = useContext(TicketContext);
	const [emails, setEmails] = useState(context.ticketData.ccList ? context.ticketData.ccList : []);

	const { instance, accounts } = useMsal();
	const account = useAccount(accounts[0] || {});

	const getToken = (callback) => {
		if (account) {
			instance.acquireTokenSilent({
				...tokenRequest,
				account: account
			}).then((response) => {
				callback(response.accessToken);
			}).catch(async (error) => {
				if (error instanceof InteractionRequiredAuthError) {
					// fallback to interaction when silent call fails
					return instance.acquireTokenPopup({
						...tokenRequest,
						account: account
					});
				}
			}).catch(error => {
				updateBanner({
					message: "An authentication error occured",
					type: "danger"
				});
			});
		}
	};

	const handleSelection = email => {
		if (!emails.map(item => item.id).includes(email.id)) {
			var em = [...emails, email];

			setEmails(em);

			updateForm({
				...context.ticketData,
				ccList: em
			});
        }
	};

	const handleDelete = id => {
		var em = [...emails];

		var filteredEm = em.filter(item => item.id !== id);

		setEmails(filteredEm);

		updateForm({
			...context.ticketData,
			ccList: filteredEm
		});
	}

	useEffect(() => {
		setEmails(context.ticketData.ccList);
	}, [context.ticketData.ccList]);

	return (
		<div className="form-group">
			<label htmlFor="emailSearchBox">Users to be copied on Updates (submitter not required)</label>
			<DynamicSearchBox
				callback={handleSelection}
				getOptions={(query, setOptions) => {
					getToken((resp) => { getUserEmail(query, resp, setOptions, errorReceived); });
				}}
				controlID={"emailSearchBox"}
				displayName={"name"}
			/>

			<ul className="list-group list-group-flush">
			{
				emails.map((email) =>
					<li className="list-group-item" key={email.id}>
						<FontAwesomeIcon icon={faTimesCircle} color="indianred" onClick={() => handleDelete(email.id)} />
						&nbsp;
						{email.emailAddress}
					</li>
				)
            }
			</ul>
		</div>
	);
}