import React from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import KaceTicketForm from './components/KaceTicketForm';
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";

import './custom.css'
import ConfirmationPage from './components/Confirmation';

export default function App() {
    return (
        <React.Fragment>
            <AppInsightsContext.Provider value={reactPlugin}>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
                    <Layout>
                        <Route
                            exact path='/'
                            component={KaceTicketForm}
                        />
                        <Route
                            exact path='/confirmation'
                            component={ConfirmationPage}
                        />
                    </Layout>
                </MsalAuthenticationTemplate>
            </AppInsightsContext.Provider>
        </React.Fragment>
    );
}
